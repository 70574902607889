import React, { useState } from "react"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import styles from "./navbar.module.css"
import { useTranslation } from "react-i18next"

export default function NavBar({ classVariant, logo }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const { t } = useTranslation()

  const header = t("header")
  const renderHeader = []

  for (const item of header) {
    let link

    if (item.url.includes("#")) {
      link = (
        <AnchorLink
          to={item.url}
          title={item.text}
          className={styles.navBarLink}
        >
          {item.text}
        </AnchorLink>
      )
    } else {
      link = (
        <Nav.Link href={item.url} className={styles.navBarLink}>
          {item.text}
        </Nav.Link>
      )
    }
    renderHeader.push(link)
  }

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu)
  }

  return (
    <Navbar
      className={`${styles.navBar} ${classVariant ? styles[classVariant] : ""}`}
      expand="md"
      bg="dark"
      variant="dark"
      role="navigation"
    >
      <Container fluid="md">
        <Navbar.Brand href="/" className={styles.navBarBrand}>
          <img src={logo} className={styles.logo} alt="Ecomaxx" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={`${styles.navBarToggle} ${
            isOpenMenu ? styles.navBarToggleOpen : ""
          }`}
          onClick={toggleMenu}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={styles.navBarCollapse}
        >
          <Nav className="ml-auto">{renderHeader}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
